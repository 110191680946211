import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import ReactGA from "react-ga4";
import Button from 'components/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import UserService from 'services/user.service';
import Order from './Order';
import {
  setShowAccountModal,
  setShowLoginModal,
  setShowSaveProjectModal,
  setSelectedProject,
  // setShowIframeModal,
  setComment,
} from '../../store/reducers/User/UserSlice';
import {
  useAddQuoteMutation,
  useGenerateNumber,
} from '../../services/quotes.service';

import {
  setOrder,
  setOrderSolar,
  clearOrder,
  clearOrderSolar,
  setTypeModel,
} from '../../store/reducers/Project/ProjectSlice';

import {
  useGetProductsPriceQuery,
  useAddProjectMutation,
  useUpdateProjectMutation,
} from '../../services/projects.service';
import thermogreen from '../../assets/thermogreen.png';
import person from '../../assets/person.png';

import { ReactComponent as Folder } from '../../assets/folder.svg';
import { ReactComponent as Save } from '../../assets/save.svg';
import { ReactComponent as Send } from '../../assets/send.svg';
import { ReactComponent as Arrow } from '../../assets/arrow-top.svg';
import { ReactComponent as ArrowScroll } from '../../assets/arrow-scroll.svg';

import Comments from './Comments';
import Elemente from './Elemente';
import Extras from './Extras';

import Farba from './Farba';
import './style.scss';
import Zubehor from './Zubehor';

const Accordion = () => {
  const dispatch = useDispatch();
  const { profile, selectedProject, comment } = useSelector(
    (state) => state.user
  );
  const { source, typeModel, openingProject } = useSelector(
    (state) => state.project
  );

  const [section, setSection] = useState(null);
  const { order, orderSolar } = useSelector((state) => state.project);
  const [priceUpdate, setPriceUpdate] = useState(true);

  const { data: productPrice, isLoading } = useGetProductsPriceQuery({
    products: order,
  });

  const [deliveryData, setDeliveryData] = useState({});
  const [deliveryFlag, setDeliveryFlag] = useState(false);

  const delivery = async () => {
    if (!profile) return { distance: 0, duration: 0 };
    try {
      const response = await UserService.postDelivery({
        postcode: profile.postcode,
      });
      return {
        distance: response?.rows[0]?.elements[0]?.distance.value,
        duration: response?.rows[0]?.elements[0]?.duration.value,
      };
    } catch (err) {
      console.error(err);
      return { distance: 0, duration: 0 };
    }
  };

  useEffect(() => {
    if (!source) return;
    source?.addEventListener('new-type-model', (data) => {
      dispatch(setTypeModel(data.model));
    });

    source?.addEventListener('open-section1', () => {
      setSection('section1');
    });

    source?.addEventListener('update-price', async (data) => {
      setPriceUpdate(false);
      dispatch(clearOrder());
      dispatch(clearOrderSolar());
      // extrass
      if (data.extras.servicesTG.planung) {
        dispatch(
          setOrder({
            id: '1008',
            qty: 1,
          })
        );
        dispatch(
          setOrder({
            id: '1544',
            qty: 1,
          })
        );
      }
      if (data.extras.servicesTG.montagePlanung) {
        dispatch(
          setOrder({
            id: '1005',
            qty: 1,
          })
        );
        dispatch(
          setOrder({
            id: '1008',
            qty: 1,
          })
        );
        dispatch(
          setOrder({
            id: '1544',
            qty: 1,
          })
        );
      }
      if (data.extras.servicesTG.baugensuch) {
        dispatch(
          setOrder({
            id: '1090',
            qty: 1,
          })
        );
      }
      if (data.extras.servicesTG.aufmassVorOrt) {
        dispatch(
          setOrder({
            id: '1551',
            qty: 1,
          })
        );
      }
      if (data.extras.externalServicesTG.fundamente) {
        dispatch(
          setOrder({
            id: '1094',
            qty: data.extras.externalServicesTG.colFundamente,
          })
        );
      }
      if (data.extras.externalServicesTG.plattenpassen) {
        dispatch(
          setOrder({
            id: '1097',
            qty: data.extras.externalServicesTG.colPlattenpassen,
          })
        );
      }
      // elements
      data.elements.forEach((el) => {
        if (el.model.type === 'louver') {
          dispatch(
            setOrder({
              id: '1061',
              qty: 1,
              Variables: [
                {
                  VariableName: 'Breite',
                  VariableValue: +(data.size.w / 10),
                },
                {
                  VariableName: 'Tiefe',
                  VariableValue: +(data.size.d / 10),
                },
                {
                  VariableName: 'HoeheOKT',
                  VariableValue: +(data.size.h1 / 10),
                },
              ],
            })
          );
        }
        if (el.model.type === 'louver2') {
          dispatch(
            setOrder({
              id: '1064',
              qty: 1,
              Variables: [
                {
                  VariableName: 'Breite',
                  VariableValue: +(data.size.w / 10),
                },
                {
                  VariableName: 'Tiefe',
                  VariableValue: +(data.size.d / 10),
                },
                {
                  VariableName: 'HoeheOKT',
                  VariableValue: +(data.size.h1 / 10),
                },
              ],
            })
          );
        }
        if (el.model.type === 'louver3') {
          dispatch(
            setOrder({
              id: '1484',
              qty: 1,
              Variables: [
                {
                  VariableName: 'Breite',
                  VariableValue: +(data.size.w / 10),
                },
                {
                  VariableName: 'Tiefe',
                  VariableValue: +(data.size.d / 10),
                },
                {
                  VariableName: 'HoeheOKT',
                  VariableValue: +(data.size.h1 / 10),
                },
              ],
            })
          );
        }
        if (el.model.type === 'glass') {
          dispatch(
            setOrder({
              id: '1001',
              qty: 1,
              Variables: [
                {
                  VariableName: 'Breite',
                  VariableValue: +(data.size.w / 10),
                },
                {
                  VariableName: 'Tiefe',
                  VariableValue: +(data.size.d / 10),
                },
                {
                  VariableName: 'HoeheUKT',
                  VariableValue: +(data.size.h1 / 10),
                },
                {
                  VariableName: 'HoeheOKW',
                  VariableValue: +(data.size.h2 / 10),
                },
              ],
            })
          );
          dispatch(
            setOrder({
              id: '1055',
              qty: 1,
            })
          );
        }
        el.arrLed.forEach((item) => {
          if (item !== undefined) {
            if (item.type === 'direct' || item.type === 'inderect') {
              let color = 0;
              let seiten = 0;
              if (item.color === 'white') {
                color = 0;
              }
              if (item.color === 'rgb') {
                color = 1;
              }
              if (item.seiten === 'seiten-4') {
                seiten = 0;
              }
              if (item.seiten === 'seiten-2') {
                seiten = 1;
              }
              dispatch(
                setOrder({
                  id: '1547',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.w / 10),
                    },
                    {
                      VariableName: 'Tiefe',
                      VariableValue: +(data.size.d / 10),
                    },
                    {
                      VariableName: 'RGB',
                      VariableValue: color,
                    },
                    {
                      VariableName: 'NurSeiten',
                      VariableValue: seiten,
                    },
                  ],
                })
              );
            }

            if (item.type === 'spots') {
              dispatch(
                setOrder({
                  id: '1250',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.w / 10),
                    },
                    {
                      VariableName: 'Tiefe',
                      VariableValue: +el.model.sparrenLenght.toFixed(2),
                    },
                    {
                      VariableName: 'Sparren',
                      VariableValue: +item.sparren,
                    },
                    {
                      VariableName: 'LEDproSparren',
                      VariableValue: +item.spots,
                    },
                  ],
                })
              );
            }
          }
        });
        el.arrWindow.forEach((item) => {
          if (item.type === 'windowType1' && el.model.type === 'louver') {
            if (item.side === 'sideA' || item.side === 'sideC') {
              dispatch(
                setOrder({
                  section: el.colSectionWindow[item.side],
                  side: item.side,
                  id: '1553',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.d / 10) - 20,
                    },
                    {
                      VariableName: 'HoeheUKT',
                      VariableValue: +(data.size.h1 / 10) - 20,
                    },
                    {
                      VariableName: 'FFAnzahlElemente',
                      VariableValue: el.colSectionWindow[item.side],
                    },
                  ],
                })
              );
              dispatch(
                setOrder({
                  side: item.side,
                  squar:
                    ((+data.size.d / 10 - 20) * (+data.size.h1 / 10 - 20)) /
                    10000,
                  id: '1025',
                  qty: 1,
                })
              );
            }

            if (item.side === 'sideB' || item.side === 'sideD') {
              dispatch(
                setOrder({
                  section: el.colSectionWindow[item.side],
                  side: item.side,
                  id: '1553',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.w / 10) - 40,
                    },
                    {
                      VariableName: 'HoeheUKT',
                      VariableValue: +(data.size.h1 / 10) - 20,
                    },
                    {
                      VariableName: 'FFAnzahlElemente',
                      VariableValue: el.colSectionWindow[item.side],
                    },
                  ],
                })
              );
              dispatch(
                setOrder({
                  side: item.side,
                  squar:
                    ((+data.size.w / 10 - 40) * (+data.size.h1 / 10 - 20)) /
                    10000,
                  id: '1025',
                  qty: 1,
                })
              );
            }
          }
          if (item.type === 'windowType1' && el.model.type === 'louver3') {
            if (item.side === 'sideA' || item.side === 'sideC') {
              dispatch(
                setOrder({
                  section: el.colSectionWindow[item.side],
                  side: item.side,
                  id: '1553',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.d / 10) - 26,
                    },
                    {
                      VariableName: 'HoeheUKT',
                      VariableValue: +(data.size.h1 / 10) - 26,
                    },
                    {
                      VariableName: 'FFAnzahlElemente',
                      VariableValue: el.colSectionWindow[item.side],
                    },
                  ],
                })
              );
              dispatch(
                setOrder({
                  side: item.side,
                  squar:
                    ((+data.size.d / 10 - 26) * (+data.size.h1 / 10 - 26)) /
                    10000,
                  id: '1025',
                  qty: 1,
                })
              );
            }

            if (item.side === 'sideB' || item.side === 'sideD') {
              dispatch(
                setOrder({
                  section: el.colSectionWindow[item.side],
                  side: item.side,
                  id: '1553',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.w / 10) - 26,
                    },
                    {
                      VariableName: 'HoeheUKT',
                      VariableValue: +(data.size.h1 / 10) - 26,
                    },
                    {
                      VariableName: 'FFAnzahlElemente',
                      VariableValue: el.colSectionWindow[item.side],
                    },
                  ],
                })
              );
              dispatch(
                setOrder({
                  side: item.side,
                  squar:
                    ((+data.size.w / 10 - 26) * (+data.size.h1 / 10 - 26)) /
                    10000,
                  id: '1025',
                  qty: 1,
                })
              );
            }
          }
          if (item.type === 'windowType1' && el.model.type === 'glass') {
            if (item.side === 'sideA' || item.side === 'sideC') {
              dispatch(
                setOrder({
                  section: el.colSectionWindow[item.side],
                  side: item.side,
                  id: '1554',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.d / 10) - 13.5,
                    },
                    {
                      VariableName: 'Hoehe',
                      VariableValue: +(data.size.h2 / 10),
                    },
                    {
                      VariableName: 'AnzahlElementeinderBreite',
                      VariableValue: el.colSectionWindow[item.side],
                    },
                    {
                      VariableName: 'AnzahlElementeinderHoehe',
                      VariableValue: 2,
                    },
                  ],
                })
              );

              dispatch(
                setOrder({
                  side: item.side,
                  squar:
                    ((+data.size.d / 10 - 13.5) * (+data.size.h2 / 10)) / 10000,
                  id: '1058',
                  qty: 1,
                })
              );
            }
            if (item.side === 'sideB') {
              if (+data.size.w > 7000 + 200) {
                dispatch(
                  setOrder({
                    section: el.colSectionWindow[item.side],
                    side: item.side,
                    id: '1554',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +(data.size.w / 10 / 2) - 20,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +(data.size.h1 / 10) - 15,
                      },
                      {
                        VariableName: 'AnzahlElementeinderBreite',
                        VariableValue: el.colSectionWindow[item.side],
                      },
                      {
                        VariableName: 'AnzahlElementeinderHoehe',
                        VariableValue: 1,
                      },
                    ],
                  })
                );
                dispatch(
                  setOrder({
                    side: item.side,
                    squar:
                      ((+data.size.w / 10 / 2 - 20) *
                        (+data.size.h1 / 10 - 15)) /
                      10000,
                    id: '1058',
                    qty: 2,
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    section: el.colSectionWindow[item.side],
                    side: item.side,
                    id: '1554',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +(data.size.w / 10) - 20,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +(data.size.h1 / 10) - 15,
                      },
                      {
                        VariableName: 'AnzahlElementeinderBreite',
                        VariableValue: el.colSectionWindow[item.side],
                      },
                      {
                        VariableName: 'AnzahlElementeinderHoehe',
                        VariableValue: 1,
                      },
                    ],
                  })
                );
                dispatch(
                  setOrder({
                    side: item.side,
                    squar:
                      ((+data.size.w / 10 - 20) * (+data.size.h1 / 10 - 15)) /
                      10000,
                    id: '1058',
                    qty: 1,
                  })
                );
              }
            }
          }
          if (item.type === 'windowType2' && el.model.type === 'louver') {
            let idProduct = null;
            if (el.colSectionWindow[item.side] === 2) {
              idProduct = '1134';
            }
            if (el.colSectionWindow[item.side] === 3) {
              idProduct = '1135';
            }
            if (el.colSectionWindow[item.side] === 4) {
              idProduct = '1136';
            }
            if (el.colSectionWindow[item.side] === 5) {
              idProduct = '1137';
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              dispatch(
                setOrder({
                  side: item.side,
                  id: idProduct,
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.w / 10) - 40,
                    },
                    {
                      VariableName: 'Hoehe',
                      VariableValue: +(data.size.h1 / 10) - 20,
                    },
                  ],
                })
              );
            }
            if (item.side === 'sideA' || item.side === 'sideC') {
              dispatch(
                setOrder({
                  side: item.side,
                  id: idProduct,
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.d / 10) - 20,
                    },
                    {
                      VariableName: 'Hoehe',
                      VariableValue: +(data.size.h1 / 10) - 20,
                    },
                  ],
                })
              );
            }
          }
          if (item.type === 'windowType2' && el.model.type === 'louver3') {
            let idProduct = null;
            if (el.colSectionWindow[item.side] === 2) {
              idProduct = '1134';
            }
            if (el.colSectionWindow[item.side] === 3) {
              idProduct = '1135';
            }
            if (el.colSectionWindow[item.side] === 4) {
              idProduct = '1136';
            }
            if (el.colSectionWindow[item.side] === 5) {
              idProduct = '1137';
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              dispatch(
                setOrder({
                  side: item.side,
                  id: idProduct,
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.w / 10) - 26,
                    },
                    {
                      VariableName: 'Hoehe',
                      VariableValue: +(data.size.h1 / 10) - 26,
                    },
                  ],
                })
              );
            }
            if (item.side === 'sideA' || item.side === 'sideC') {
              dispatch(
                setOrder({
                  side: item.side,
                  id: idProduct,
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.d / 10) - 26,
                    },
                    {
                      VariableName: 'Hoehe',
                      VariableValue: +(data.size.h1 / 10) - 26,
                    },
                  ],
                })
              );
            }
          }
          if (item.type === 'windowType2' && el.model.type === 'glass') {
            let idProduct = null;
            if (el.colSectionWindow[item.side] === 2) {
              idProduct = '1045';
            }
            if (el.colSectionWindow[item.side] === 3) {
              idProduct = '1049';
            }
            if (el.colSectionWindow[item.side] === 4) {
              idProduct = '1048';
            }
            if (el.colSectionWindow[item.side] === 5) {
              idProduct = '1040';
            }

            if (item.side === 'sideB') {
              if (+data.size.w > 7500 + 200) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: idProduct,
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +(data.size.w / 10 / 2) - 20,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +(data.size.h1 / 10) - 15,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: idProduct,
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +(data.size.w / 10) - 20,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +(data.size.h1 / 10) - 15,
                      },
                    ],
                  })
                );
              }
            }

            if (item.side === 'sideA' || item.side === 'sideC') {
              dispatch(
                setOrder({
                  side: item.side,
                  id: idProduct,
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.d / 10) - 13.5,
                    },
                    {
                      VariableName: 'Hoehe',
                      VariableValue: +(data.size.h1 / 10) - 15,
                    },
                  ],
                })
              );
            }

            if (item.side === 'sideA' || item.side === 'sideC') {
              dispatch(
                setOrder({
                  section: el.colSectionWindow[item.side],
                  side: item.side,
                  id: '1554',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.d / 10) - 13.5,
                    },
                    {
                      VariableName: 'Hoehe',
                      VariableValue: +(
                        data.size.h2 / 10 -
                        +(data.size.h1 / 10) +
                        15
                      ),
                    },
                    {
                      VariableName: 'AnzahlElementeinderBreite',
                      VariableValue: el.colSectionWindow[item.side],
                    },
                    {
                      VariableName: 'AnzahlElementeinderHoehe',
                      VariableValue: 1,
                    },
                  ],
                })
              );

              dispatch(
                setOrder({
                  side: item.side,
                  squar:
                    ((+data.size.d / 10 - 13.5) *
                      (+data.size.h2 / 10 - +data.size.h1 / 10 + 15)) /
                    10000,
                  id: '1058',
                  qty: 1,
                })
              );
            }
          }
        });
        el.arrZip.forEach((item) => {
          if (
            item.type === 'zipType1' &&
            el.model.type === 'louver' &&
            item.side !== 'sideRoof'
          ) {
            if (item.side === 'sideA' || item.side === 'sideC') {
              if (+data.size.d > 6082 + 200 - 80) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 - 20 + 8) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20 + 14,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.d / 10 - 20 + 8,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20 + 14,
                      },
                    ],
                  })
                );
              }
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              if (+data.size.w > 6082 + 400 - 80) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.w / 10 - 40 + 8) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20 + 14,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.w / 10 - 40 + 8,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20 + 14,
                      },
                    ],
                  })
                );
              }
            }
          }

          if (
            item.type === 'zipType1' &&
            el.model.type === 'louver2' &&
            item.side !== 'sideRoof'
          ) {
            if (item.side === 'sideA' || item.side === 'sideC') {
              if (+data.size.d > 6082 + 100 - 80) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 - 10 + 8) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20 + 14,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.d / 10 - 10 + 8,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20 + 14,
                      },
                    ],
                  })
                );
              }
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              if (+data.size.w > 6082 + 300 - 80) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.w / 10 - 30 + 8) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20 + 14,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.w / 10 - 30 + 8,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20 + 14,
                      },
                    ],
                  })
                );
              }
            }
          }

          if (
            item.type === 'zipType1' &&
            el.model.type === 'louver3' &&
            item.side !== 'sideRoof'
          ) {
            if (item.side === 'sideA' || item.side === 'sideC') {
              if (+data.size.d > 6082 + 260 - 80) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 - 26 + 8) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 26 + 14,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.d / 10 - 26 + 8,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 26 + 14,
                      },
                    ],
                  })
                );
              }
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              if (+data.size.w > 6082 + 260 - 80) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.w / 10 - 26 + 8) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 26 + 14,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.w / 10 - 26 + 8,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 26 + 14,
                      },
                    ],
                  })
                );
              }
            }
          }

          if (
            item.type === 'zipType2' &&
            el.model.type === 'louver' &&
            item.side !== 'sideRoof'
          ) {
            if (item.side === 'sideA' || item.side === 'sideC') {
              if (+data.size.d > 6082 + 200) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 - 20) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.d / 10 - 20,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20,
                      },
                    ],
                  })
                );
              }
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              if (+data.size.w > 6082 + 400) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.w / 10 / 2 - 40,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.w / 10 - 40,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20,
                      },
                    ],
                  })
                );
              }
            }
          }

          if (
            item.type === 'zipType2' &&
            el.model.type === 'louver3' &&
            item.side !== 'sideRoof'
          ) {
            if (item.side === 'sideA' || item.side === 'sideC') {
              if (+data.size.d > 6082 + 260) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 - 26) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 26,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.d / 10 - 26,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 26,
                      },
                    ],
                  })
                );
              }
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              if (+data.size.w > 6082 + 260) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.w / 10 / 2 - 26,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 26,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.w / 10 - 26,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 26,
                      },
                    ],
                  })
                );
              }
            }
          }

          if (
            (item.type === 'zipType1' || item.type === 'zipType3') &&
            el.model.type === 'glass' &&
            item.side === 'sideRoof'
          ) {
            if (+data.size.w > 6000) {
              dispatch(
                setOrder({
                  id: '1477',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue:
                        +data.size.w / 10 - el.model.zipRoofSeparation * 100,
                    },
                    {
                      VariableName: 'Tiefe',
                      VariableValue: +data.size.d / 10,
                    },
                  ],
                })
              );
              dispatch(
                setOrder({
                  id: '1477',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +el.model.zipRoofSeparation * 100,
                    },
                    {
                      VariableName: 'Tiefe',
                      VariableValue: +data.size.d / 10,
                    },
                  ],
                })
              );
            } else {
              dispatch(
                setOrder({
                  id: '1477',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +data.size.w / 10,
                    },
                    {
                      VariableName: 'Tiefe',
                      VariableValue: +data.size.d / 10,
                    },
                  ],
                })
              );
            }
          }
          if (
            (item.type === 'zipType2' || item.type === 'zipType4') &&
            el.model.type === 'glass' &&
            item.side === 'sideRoof'
          ) {
            if (+data.size.w > 5000) {
              if (+data.size.w > 8000) {
                dispatch(
                  setOrder({
                    id: '1036',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue:
                          +data.size.w / 10 - el.model.zipRoofSeparation * 100,
                      },
                      {
                        VariableName: 'Tiefe',
                        VariableValue: +data.size.d / 10,
                      },
                    ],
                  })
                );
                dispatch(
                  setOrder({
                    id: '1036',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +el.model.zipRoofSeparation * 100,
                      },
                      {
                        VariableName: 'Tiefe',
                        VariableValue: +data.size.d / 10,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    id: '1037',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.w / 10,
                      },
                      {
                        VariableName: 'Tiefe',
                        VariableValue: +data.size.d / 10,
                      },
                    ],
                  })
                );
              }
            } else {
              dispatch(
                setOrder({
                  id: '1036',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +data.size.w / 10,
                    },
                    {
                      VariableName: 'Tiefe',
                      VariableValue: +data.size.d / 10,
                    },
                  ],
                })
              );
            }
          }
          if (
            (item.type === 'zipType1' || item.type === 'zipType3') &&
            el.model.type === 'glass' &&
            item.side !== 'sideRoof'
          ) {
            if (item.side === 'sideA' || item.side === 'sideC') {
              if (+data.size.d > 6000 + 135) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1096',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 + 13.5) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 + 20,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1096',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 + 13.5) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 + 20,
                      },
                    ],
                  })
                );
              }
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              if (+data.size.w > 6000 + 200) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1096',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.w / 10 + 20) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 + 20,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1096',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.w / 10 + 20) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 + 20,
                      },
                    ],
                  })
                );
              }
            }
          }
          if (
            (item.type === 'zipType2' || item.type === 'zipType4') &&
            el.model.type === 'glass' &&
            item.side !== 'sideRoof'
          ) {
            if (item.side === 'sideA' || item.side === 'sideC') {
              if (+data.size.d > 6000 + 135) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1117',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 + 13.5) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 + 20,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1117',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 + 13.5) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 + 20,
                      },
                    ],
                  })
                );
              }
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              if (+data.size.w > 6000 + 200) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1117',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 + 20) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 + 20,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1117',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 + 20) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 + 20,
                      },
                    ],
                  })
                );
              }
            }
          }
        });
        el.arrLamellen.forEach((item) => {
          if (el.model.type === 'louver') {
            if (item.side === 'sideA' || item.side === 'sideC') {
              dispatch(
                setOrder({
                  sectionLenght: el.lamellenWidth[item.side] * 100,
                  section:
                    el.colSectionLamellen[item.side][0] +
                    el.colSectionLamellen[item.side][1],
                  side: item.side,
                  id: '1546',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Anlagenbreite',
                      VariableValue: +(data.size.d / 10) - 20,
                    },
                    {
                      VariableName: 'Anlagenhoehe',
                      VariableValue: +(data.size.h1 / 10) - 20,
                    },
                    {
                      VariableName: 'AnzahlSchiebelaeden',
                      VariableValue:
                        el.colSectionLamellen[item.side][0] +
                        el.colSectionLamellen[item.side][1],
                    },
                    {
                      VariableName: 'Schiebeladenbreite',
                      VariableValue: el.lamellenWidth[item.side] * 100,
                    },
                    {
                      VariableName: 'AnzahlLaufschienen',
                      VariableValue: 2,
                    },
                  ],
                })
              );
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              dispatch(
                setOrder({
                  sectionLenght: el.lamellenWidth[item.side] * 100,
                  section:
                    el.colSectionLamellen[item.side][0] +
                    el.colSectionLamellen[item.side][1],
                  side: item.side,
                  id: '1546',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Anlagenbreite',
                      VariableValue: +(data.size.w / 10) - 40,
                    },
                    {
                      VariableName: 'Anlagenhoehe',
                      VariableValue: +(data.size.h1 / 10) - 20,
                    },
                    {
                      VariableName: 'AnzahlSchiebelaeden',
                      VariableValue:
                        el.colSectionLamellen[item.side][0] +
                        el.colSectionLamellen[item.side][1],
                    },
                    {
                      VariableName: 'Schiebeladenbreite',
                      VariableValue: el.lamellenWidth[item.side] * 100,
                    },
                    {
                      VariableName: 'AnzahlLaufschienen',
                      VariableValue: 2,
                    },
                  ],
                })
              );
            }
          }

          if (el.model.type === 'louver2') {
            if (item.side === 'sideA' || item.side === 'sideC') {
              dispatch(
                setOrder({
                  sectionLenght: el.lamellenWidth[item.side] * 100,
                  section:
                    el.colSectionLamellen[item.side][0] +
                    el.colSectionLamellen[item.side][1],
                  side: item.side,
                  id: '1546',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Anlagenbreite',
                      VariableValue: +(data.size.d / 10) - 10,
                    },
                    {
                      VariableName: 'Anlagenhoehe',
                      VariableValue: +(data.size.h1 / 10) - 20,
                    },
                    {
                      VariableName: 'AnzahlSchiebelaeden',
                      VariableValue:
                        el.colSectionLamellen[item.side][0] +
                        el.colSectionLamellen[item.side][1],
                    },
                    {
                      VariableName: 'Schiebeladenbreite',
                      VariableValue: el.lamellenWidth[item.side] * 100,
                    },
                    {
                      VariableName: 'AnzahlLaufschienen',
                      VariableValue: 2,
                    },
                  ],
                })
              );
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              dispatch(
                setOrder({
                  sectionLenght: el.lamellenWidth[item.side] * 100,
                  section:
                    el.colSectionLamellen[item.side][0] +
                    el.colSectionLamellen[item.side][1],
                  side: item.side,
                  id: '1546',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Anlagenbreite',
                      VariableValue: +(data.size.w / 10) - 30,
                    },
                    {
                      VariableName: 'Anlagenhoehe',
                      VariableValue: +(data.size.h1 / 10) - 20,
                    },
                    {
                      VariableName: 'AnzahlSchiebelaeden',
                      VariableValue:
                        el.colSectionLamellen[item.side][0] +
                        el.colSectionLamellen[item.side][1],
                    },
                    {
                      VariableName: 'Schiebeladenbreite',
                      VariableValue: el.lamellenWidth[item.side] * 100,
                    },
                    {
                      VariableName: 'AnzahlLaufschienen',
                      VariableValue: 2,
                    },
                  ],
                })
              );
            }
          }

          if (el.model.type === 'louver3') {
            if (item.side === 'sideA' || item.side === 'sideC') {
              dispatch(
                setOrder({
                  sectionLenght: el.lamellenWidth[item.side] * 100,
                  section:
                    el.colSectionLamellen[item.side][0] +
                    el.colSectionLamellen[item.side][1],
                  side: item.side,
                  id: '1546',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Anlagenbreite',
                      VariableValue: +(data.size.d / 10) - 26,
                    },
                    {
                      VariableName: 'Anlagenhoehe',
                      VariableValue: +(data.size.h1 / 10) - 26,
                    },
                    {
                      VariableName: 'AnzahlSchiebelaeden',
                      VariableValue:
                        el.colSectionLamellen[item.side][0] +
                        el.colSectionLamellen[item.side][1],
                    },
                    {
                      VariableName: 'Schiebeladenbreite',
                      VariableValue: el.lamellenWidth[item.side] * 100,
                    },
                    {
                      VariableName: 'AnzahlLaufschienen',
                      VariableValue: 2,
                    },
                  ],
                })
              );
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              dispatch(
                setOrder({
                  sectionLenght: el.lamellenWidth[item.side] * 100,
                  section:
                    el.colSectionLamellen[item.side][0] +
                    el.colSectionLamellen[item.side][1],
                  side: item.side,
                  id: '1546',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Anlagenbreite',
                      VariableValue: +(data.size.w / 10) - 26,
                    },
                    {
                      VariableName: 'Anlagenhoehe',
                      VariableValue: +(data.size.h1 / 10) - 26,
                    },
                    {
                      VariableName: 'AnzahlSchiebelaeden',
                      VariableValue:
                        el.colSectionLamellen[item.side][0] +
                        el.colSectionLamellen[item.side][1],
                    },
                    {
                      VariableName: 'Schiebeladenbreite',
                      VariableValue: el.lamellenWidth[item.side] * 100,
                    },
                    {
                      VariableName: 'AnzahlLaufschienen',
                      VariableValue: 2,
                    },
                  ],
                })
              );
            }
          }
        });
        el.arrSteering.forEach((item) => {
          if (item === 'steeringType1') {
            dispatch(
              setOrder({
                id: '1165',
                qty: 1,
              })
            );
          }
          if (item === 'steeringType2') {
            dispatch(
              setOrder({
                id: '1184',
                qty: 1,
              })
            );
          }
          if (item === 'steeringType3') {
            dispatch(
              setOrder({
                id: '1321',
                qty: 1,
              })
            );
          }
          if (item === 'steeringType4') {
            dispatch(
              setOrder({
                id: '1099',
                qty: 1,
              })
            );
          }
        });

        el.arrLed2.forEach((item) => {
          if (item === 'led2Type1') {
            dispatch(
              setOrder({
                id: '1485',
                qty: 1,
                Variables: [
                  {
                    VariableName: 'Breite',
                    VariableValue: +(data.size.w / 10),
                  },
                  {
                    VariableName: 'Tiefe',
                    VariableValue: +(data.size.d / 10),
                  },
                  {
                    VariableName: 'StarkeAusfuehrung',
                    VariableValue: 0,
                  },
                  {
                    VariableName: 'nurRGB',
                    VariableValue: 0,
                  },
                  {
                    VariableName: 'LEDWeissRGB',
                    VariableValue: 1,
                  },
                ],
              })
            );
          }
        });

        el.arrElectro.forEach((item) => {
          if (item === 'electroType1') {
            dispatch(
              setOrder({
                id: '1367',
                qty: 1,
              })
            );
          }
          if (item === 'electroType2') {
            dispatch(
              setOrder({
                id: '1548',
                qty: 1,
              })
            );
          }
        });
        el.arrRemoteControl.forEach((item) => {
          if (item === 'remoteControlType1') {
            dispatch(
              setOrder({
                id: '1292',
                qty: 1,
              })
            );
          }
          if (item === 'remoteControlType2') {
            dispatch(
              setOrder({
                id: '1292',
                qty: 1,
              })
            );
          }
          if (item === 'remoteControlType3') {
            dispatch(
              setOrder({
                id: '1293',
                qty: 1,
              })
            );
          }
        });

        if (el.colSolar) {
          dispatch(
            setOrderSolar({
              id: `Sonnenkollektoren ${el.colSolar}`,
              qty: 1,
            })
          );
        }
      });
      setDeliveryFlag((prev) => !prev);
    });

    (async () => {
      if (!profile) return;
      setDeliveryData(await delivery());
    })();
  }, [profile]);

  const getIHours = useCallback(
    (id) => {
      const hours = productPrice?.find((el) => el.ProductNo === id);
      if (hours) {
        return hours.InstallationHours;
      }
      return 0;
    },
    [productPrice]
  );

  useEffect(() => {
    let totalInstallationHours = 0;
    order.forEach((item) => {
      totalInstallationHours += getIHours(item.id) * item.qty;
    });

    order.forEach((item) => {
      if (item.id === '1551') {
        dispatch(
          setOrder({
            id: '1551',
            qty: 0,
            Variables: [
              {
                VariableName: 'DistanzKMTGzuKD',
                VariableValue: deliveryData.distance / 1000,
              },
              {
                VariableName: 'FahrzeitStd',
                VariableValue: deliveryData.duration / 3600,
              },
              {
                VariableName: 'AufmassDauerStd',
                VariableValue: 1,
              },
            ],
          })
        );
      }

      if (item.id === '1544') {
        dispatch(
          setOrder({
            id: '1544',
            qty: 0,
            Variables: [
              {
                VariableName: 'MontageStundenAnzahl',
                VariableValue: totalInstallationHours,
              },
              {
                VariableName: 'DistanzKMTGzuKD',
                VariableValue: deliveryData.distance / 1000,
              },
              {
                VariableName: 'FahrzeitStd',
                VariableValue: deliveryData.duration / 3600,
              },
            ],
          })
        );
      }
    });
  }, [productPrice, deliveryFlag]);

  const format = (str) => {
    if (!str) return 0;
    const res = str.replace(/(\d)(?=(\d{3})+(\D|$))/g, `$1'`);
    return res;
  };
  const getPrice = useCallback(
    (
      id,
      qty,
      side,
      colsectionWindow,
      colsectionLammelen,
      sectionLenght,
      squar
    ) => {
      // console.log('>>>>>>>>>>>>>>>', productPrice);
      const price = productPrice?.find(
        (el) =>
          el.ProductNo === id &&
          el.side === side &&
          (el.section === colsectionWindow ||
            el.section === colsectionLammelen) &&
          el.sectionLenght === sectionLenght &&
          el.squar === squar
      );

      if (price) {
        const formatPrice = format((price.PriceSales * qty).toFixed(0));
        return `${formatPrice}.-`;
      }
      return `${0}.-`;
    },
    [productPrice]
  );
  const getName = useCallback(
    (id) => {
      const price = productPrice?.find((el) => el.ProductNo === id);
      if (price) {
        return price.TitleSales;
      }
      return id;
    },
    [productPrice]
  );
  const getPHours = useCallback(
    (id) => {
      const hours = productPrice?.find((el) => el.ProductNo === id);
      if (hours) {
        return hours.PlanningHours;
      }
      return 0;
    },
    [productPrice]
  );

  const total = useMemo(
    () =>
      order.reduce((acc, item) => {
        const price = productPrice?.find((el) => el.ProductNo === item.id);

        if (price) {
          const buffer =
            (+acc + price.PriceSales * item.qty)?.toFixed(0) || acc;

          return buffer;
        }
        return acc;
      }, 0),
    [productPrice, order]
  );

  const handleShowAccount = (slug) => () => {
    setSection(null);
    if (profile) {
      dispatch(setShowAccountModal(slug));
    } else {
      dispatch(setShowLoginModal(true));
    }
  };
  const handleShowSaveProject = () => {
    if (profile) {
      dispatch(setShowSaveProjectModal(true));
    } else {
      dispatch(setShowLoginModal(true));
    }
  };

  // const handleShowIframeModal = () => {
  //   dispatch(setShowIframeModal(true));
  // };

  const [addQuote, { isLoading: isQuoter }] = useAddQuoteMutation();
  const [addProject, { isLoading: isCreating }] = useAddProjectMutation();
  const [updateProject, { isLoading: isUpdating }] = useUpdateProjectMutation();

  const {
    number,
    isLoading: isNumber,
    refresh,
  } = useGenerateNumber({
    skip: !profile,
  });

  // eslint-disable-next-line no-unused-vars
  const sendProduct = async (screenScene, screenSceneTop, contact) => {
    try {
      const config = source.funcSaveProject();
      let bufferData = null;
      if (!selectedProject) {
        const { data } = await addProject(config);
        dispatch(setSelectedProject(data));
        bufferData = data;
      } else {
        config._id = selectedProject._id;
        updateProject(config);
      }
      await addQuote({
        innerprice: document.getElementById('accordion-item-price').innerHTML,
        // distance: deliveryData.distance,
        // duration: deliveryData.duration,
        // order: order.map((item) => ({
        //   name: getName(item.id),
        //   idProduct: item.id,
        //   ihours: getIHours(item.id),
        //   phours: getPHours(item.id),
        //   qty: item.qty,
        //   price: getPrice(item.id, item.qty),
        // })),
        comment: '',
        number,
        projectName: selectedProject ? selectedProject.name : bufferData.name,
        project: selectedProject ? selectedProject._id : bufferData._id,
      });
      refresh();
      dispatch(setSelectedProject(null));
      await UserService.sendOrder({
        screenScene,
        screenSceneTop,
        profile: contact,
        innerprice: document.getElementById('accordion-item-price').innerHTML,
        comment,
        // distance: deliveryData.distance,
        // duration: deliveryData.duration,
        // order: order.map((item) => ({
        //   name: getName(item.id),
        //   idProduct: item.id,
        //   ihours: getIHours(item.id),
        //   phours: getPHours(item.id),
        //   qty: item.qty,
        //   price: getPrice(item.id, item.qty),
        // })),
      });
      dispatch(setComment(''));
    } catch (err) {
      console.error(err);
    }
  };

  const handleEventGA = () => {
    ReactGA.event("conversion", {
      send_to: "AW-1035782259/K7HlCJL3x5QaEPOQ8-0D",
      value: 500.0,
      currency: "CHF",
    });
    console.log('ga-event')
  }


  const sendMessage = async () => {
    if (!profile) {
      dispatch(setShowLoginModal(true));
    } else {
      // handleShowIframeModal();
      source.funcControlsAllow(false)
      handleEventGA()
      setTimeout(async () => {
        const screen = await source.funcScreenScene();
        window.open('https://www.thermogreen.ch/danke/', '_blank');
        sendProduct(screen.screenScene, screen.screenSceneTop, profile);
      }, 500);
    }
  };

  const [sideWall, setSideWall] = useState(false);

  useEffect(() => {
    if (!source) return;
    source?.addEventListener('check-side', (data) => {
      setSideWall(data.wall);
    });
  }, [source]);

  const scrollToBottom = (numberScroll) => {
    if (!document.getElementById('accordion')) return;

    if (numberScroll === 1)
      document.getElementById('scroll-section1').scrollTop =
        document.getElementById('scroll-section1').scrollHeight;
    if (numberScroll === 2)
      document.getElementById('scroll-section2').scrollTop =
        document.getElementById('scroll-section2').scrollHeight;
    if (numberScroll === 3)
      document.getElementById('scroll-section3').scrollTop =
        document.getElementById('scroll-section3').scrollHeight;
    if (numberScroll === 4)
      document.getElementById('scroll-section4').scrollTop =
        document.getElementById('scroll-section4').scrollHeight;
    if (numberScroll === 5)
      document.getElementById('scroll-section5').scrollTop =
        document.getElementById('scroll-section5').scrollHeight;
  };

  const refsection1 = useRef(null);
  const refsection2 = useRef(null);
  const refsection3 = useRef(null);
  const refsection4 = useRef(null);
  const refsection5 = useRef(null);

  const [hasScroll1, setHasScroll1] = useState(false);
  const [hasScroll2, setHasScroll2] = useState(false);
  const [hasScroll3, setHasScroll3] = useState(false);
  const [hasScroll4, setHasScroll4] = useState(false);
  const [hasScroll5, setHasScroll5] = useState(false);

  const [showScrollButton1, setShowScrollButton1] = useState(true);
  const [showScrollButton2, setShowScrollButton2] = useState(true);
  const [showScrollButton3, setShowScrollButton3] = useState(true);
  const [showScrollButton4, setShowScrollButton4] = useState(true);
  const [showScrollButton5, setShowScrollButton5] = useState(true);

  const [resizeObserver, setResizeObserver] = useState(true);

  const handleScroll = () => {
    if (!document.getElementById('accordion')) return;
    if (section === 'section1') {
      const scrolledToBottom1 =
        refsection1.current.scrollTop + refsection1.current.clientHeight >=
        refsection1.current.scrollHeight;
      setShowScrollButton1(!scrolledToBottom1);
    }
    if (section === 'section2') {
      const scrolledToBottom2 =
        refsection2.current.scrollTop + refsection2.current.clientHeight >=
        refsection2.current.scrollHeight;
      setShowScrollButton2(!scrolledToBottom2);
    }
    if (section === 'section3') {
      const scrolledToBottom3 =
        refsection3.current.scrollTop + refsection3.current.clientHeight >=
        refsection3.current.scrollHeight;
      setShowScrollButton3(!scrolledToBottom3);
    }
    if (section === 'section4') {
      const scrolledToBottom4 =
        refsection4.current.scrollTop + refsection4.current.clientHeight >=
        refsection4.current.scrollHeight;
      setShowScrollButton4(!scrolledToBottom4);
    }
    if (section === 'section5') {
      const scrolledToBottom5 =
        refsection5.current.scrollTop + refsection5.current.clientHeight >=
        refsection5.current.scrollHeight;
      setShowScrollButton5(!scrolledToBottom5);
    }
  };

  useEffect(() => {
    handleScroll();
  }, [resizeObserver]);

  useEffect(() => {
    refsection1?.current?.addEventListener('scroll', handleScroll);
    refsection2?.current?.addEventListener('scroll', handleScroll);
    refsection3?.current?.addEventListener('scroll', handleScroll);
    refsection4?.current?.addEventListener('scroll', handleScroll);
    refsection5?.current?.addEventListener('scroll', handleScroll);
    return () => {
      refsection1?.current?.removeEventListener('scroll', handleScroll);
      refsection2?.current?.removeEventListener('scroll', handleScroll);
      refsection3?.current?.removeEventListener('scroll', handleScroll);
      refsection4?.current?.removeEventListener('scroll', handleScroll);
      refsection5?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [section]);

  const [resize, setResize] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setResize((prev) => !prev);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [section]);

  useEffect(() => {
    if (refsection1.current) {
      setHasScroll1(
        refsection1.current.scrollHeight > refsection1.current.clientHeight
      );
    }
    if (refsection2.current) {
      setHasScroll2(
        refsection2.current.scrollHeight > refsection2.current.clientHeight
      );
    }
    if (refsection3.current) {
      setHasScroll3(
        refsection3.current.scrollHeight > refsection3.current.clientHeight
      );
    }
    if (refsection4.current) {
      setHasScroll4(
        refsection4.current.scrollHeight > refsection4.current.clientHeight
      );
    }
    if (refsection5.current) {
      setHasScroll5(
        refsection5.current.scrollHeight > refsection5.current.clientHeight
      );
    }
  }, [section, sideWall, order, resize]);

  return (
    <div className="container-accordion">
      <div className="header-accordion">
        <div className="item-header-accordion">
          <img src={thermogreen} alt="" />
        </div>
        <div className="item-header-accordion">
          <Button onClick={handleShowAccount('profile')} className="person-img">
            <img src={person} alt="" width={24} height={24} />
          </Button>
        </div>
      </div>
      <div className="body-accordion">
        <div className="accordion-section">
          <Button
            className={
              section === 'section1'
                ? 'accordion-section-title active'
                : 'accordion-section-title'
            }
            onClick={() => {
              if (section === 'section1') {
                setSection(null);
              } else {
                setSection('section1');
              }
            }}
          >
            <span>Elemente</span>
            <Arrow className={section === 'section1' ? 'rotate-open' : null} />
          </Button>

          <div className="wrapper-accordion-section-content">
            <div
              ref={refsection1}
              id="scroll-section1"
              className={
                section === 'section1'
                  ? 'accordion-section-content active'
                  : 'accordion-section-content'
              }
            >
              <Elemente />
            </div>

            {section === 'section1' &&
              hasScroll1 &&
              (showScrollButton1 ? (
                <div className="accordion-item-scroll-down">
                  <div
                    role="presentation"
                    onClick={() => {
                      scrollToBottom(1);
                    }}
                  >
                    <ArrowScroll />
                  </div>
                </div>
              ) : null)}
          </div>
        </div>

        <div className="accordion-section">
          <Button
            className={
              section === 'section2'
                ? 'accordion-section-title active'
                : 'accordion-section-title'
            }
            onClick={() => {
              if (section === 'section2') {
                setSection(null);
              } else {
                setSection('section2');
              }
            }}
          >
            <span>Farbauswahl</span>
            <Arrow className={section === 'section2' ? 'rotate-open' : null} />
          </Button>

          <div className="wrapper-accordion-section-content">
            <div
              ref={refsection2}
              id="scroll-section2"
              className={
                section === 'section2'
                  ? 'accordion-section-content active'
                  : 'accordion-section-content'
              }
            >
              <Farba setResizeObserver={setResizeObserver} />
            </div>
            {section === 'section2' &&
              hasScroll2 &&
              (showScrollButton2 ? (
                <div className="accordion-item-scroll-down">
                  <div
                    role="presentation"
                    onClick={() => {
                      scrollToBottom(2);
                    }}
                  >
                    <ArrowScroll />
                  </div>
                </div>
              ) : null)}
          </div>
        </div>

        <div className="accordion-section">
          <Button
            className={
              section === 'section5'
                ? 'accordion-section-title active'
                : 'accordion-section-title'
            }
            onClick={() => {
              if (section === 'section5') {
                setSection(null);
              } else {
                setSection('section5');
              }
            }}
          >
            <span>Zubehör</span>
            <Arrow className={section === 'section5' ? 'rotate-open' : null} />
          </Button>

          <div className="wrapper-accordion-section-content">
            <div
              ref={refsection5}
              id="scroll-section5"
              className={
                section === 'section5'
                  ? 'accordion-section-content active'
                  : 'accordion-section-content'
              }
            >
              <Zubehor />
            </div>
            {section === 'section5' &&
              hasScroll5 &&
              (showScrollButton5 ? (
                <div className="accordion-item-scroll-down">
                  <div
                    role="presentation"
                    onClick={() => {
                      scrollToBottom(5);
                    }}
                  >
                    <ArrowScroll />
                  </div>
                </div>
              ) : null)}
          </div>
        </div>

        <div className="accordion-section">
          <Button
            className={
              section === 'section3'
                ? 'accordion-section-title active'
                : 'accordion-section-title'
            }
            onClick={() => {
              if (section === 'section3') {
                setSection(null);
              } else {
                setSection('section3');
              }
            }}
          >
            <span>Dienstleistungen</span>
            <Arrow className={section === 'section3' ? 'rotate-open' : null} />
          </Button>

          <div className="wrapper-accordion-section-content">
            <div
              ref={refsection3}
              id="scroll-section3"
              className={
                section === 'section3'
                  ? 'accordion-section-content active'
                  : 'accordion-section-content'
              }
            >
              <Extras />
            </div>
            {section === 'section3' &&
              hasScroll3 &&
              (showScrollButton3 ? (
                <div className="accordion-item-scroll-down">
                  <div
                    role="presentation"
                    onClick={() => {
                      scrollToBottom(3);
                    }}
                  >
                    <ArrowScroll />
                  </div>
                </div>
              ) : null)}
          </div>
        </div>
        <div className="accordion-section">
          <Button
            className={
              section === 'section4'
                ? 'accordion-section-title active'
                : 'accordion-section-title'
            }
            onClick={() => {
              if (section === 'section4') {
                setSection(null);
              } else {
                setSection('section4');
                source?.funcUpdatePrice();
              }
            }}
          >
            <span>Kostenschätzung</span>
            <Arrow className={section === 'section4' ? 'rotate-open' : null} />
          </Button>

          <div className="wrapper-accordion-section-content">
            <div
              ref={refsection4}
              id="scroll-section4"
              className={
                section === 'section4'
                  ? 'accordion-section-content-price active'
                  : 'accordion-section-content-price'
              }
            >
              {!profile ? (
                <div className="accordion-item">
                  <div className="accordion-item-title">
                    <span>
                      Für eine Kostenschätzung, bitte
                      <span
                        onClick={handleShowAccount('profile')}
                        role="presentation"
                      >
                        anmelden.
                      </span>
                    </span>
                  </div>
                </div>
              ) : (
                <div style={section !== 'section4' ? { display: 'none' } : {}}>
                  <div
                    className="accordion-item-price"
                    id="accordion-item-price"
                  >
                    <Order
                      {...{
                        order,
                        orderSolar,
                        getName,
                        getPrice,
                        getPHours,
                        getIHours,
                        total: format(total),
                        isLoading,
                        typeModel,

                        setPriceUpdate,
                      }}
                    />
                  </div>
                  <Comments />
                </div>
              )}
            </div>
            {section === 'section4' &&
              hasScroll4 &&
              (showScrollButton4 ? (
                <div className="accordion-item-scroll-down">
                  <div
                    role="presentation"
                    onClick={() => {
                      scrollToBottom(4);
                    }}
                  >
                    <ArrowScroll />
                  </div>
                </div>
              ) : null)}
          </div>
        </div>
      </div>
      <div className="footer-accordion">
        <div className="item-footer-accordion">
          <Button
            className="btn-footer-accordion"
            onClick={handleShowAccount('projects')}
          >
            <Folder />
          </Button>
          <Button
            className="btn-footer-accordion"
            onClick={handleShowSaveProject}
          >
            <Save />
          </Button>
        </div>
        <Button
          onClick={sendMessage}
          disabled={
            isQuoter ||
            isNumber ||
            isCreating ||
            isUpdating ||
            isLoading ||
            !priceUpdate ||
            !openingProject
          }
          className="item-btn-footer-accordion"
        >
          <Send /> <span>Anfragen</span>
        </Button>
      </div>
    </div>
  );
};

export default Accordion;
