import { useDispatch, useSelector } from 'react-redux';
import ReactGA from "react-ga4";
import CustomSimpleBar from 'components/CustomSimpleBar';
// import Extras from '../../Accordion/Extras';
import Comments from '../../Accordion/Comments';
import Order from '../../Accordion/Order';
import { decrementStep } from '../../../store/reducers/User/UserSlice';
import SidePanelFooter from '../SidePanelFooter';
import './style.scss';

const Step6 = ({
  getIHours,
  isLoading,
  getPHours,
  getName,
  setPriceUpdate,
  getPrice,
  total,
  order,
  orderSolar,
  sendMessage,
  totalVal,
}) => {
  const dispatch = useDispatch();
  const activeStep = useSelector((state) => state.user.activeStep);
  const { typeModel } = useSelector((state) => state.project);


  const handleEventGA = () => {
    ReactGA.event("conversion", {
      send_to: "AW-1035782259/K7HlCJL3x5QaEPOQ8-0D",
      value: 500.0,
      currency: "CHF",
    });
    console.log('ga-event')
  }

  const handleNext = () => {
    handleEventGA()
    sendMessage();
  };

  const handleBack = () => {
    dispatch(decrementStep());
  };
  return (
    <>
      <div className="step6">
        <CustomSimpleBar style={{ maxHeight: '100%', width: '100%' }}>
          <div className="step6__infoWrap">
            {/* <Extras /> */}
            {/* <div className="step6__lineWrap">
              <div className="step6__lineWrap-line" />
            </div> */}

            <div className="step6__infoWrap-infoBlock">
              {!isLoading && (
                <div id="accordion-item-price">
                  <Order
                    {...{
                      order,
                      orderSolar,
                      getName,
                      getPrice,
                      getPHours,
                      getIHours,
                      total,
                      isLoading,
                      typeModel,
                      setPriceUpdate,
                    }}
                  />
                </div>
              )}
              <button
                type="button"
                style={{ display: 'none' }}
                onClick={sendMessage}
              >
                button
              </button>
            </div>
            <Comments />
          </div>
        </CustomSimpleBar>
      </div>
      <SidePanelFooter
        isLoading={isLoading}
        totalVal={totalVal}
        handleNext={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
      />
    </>
  );
};

export default Step6;
